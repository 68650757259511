import React from 'react'

import styles from './Radio.module.scss'

type Props = {
  name: string
  value: string
  disabled?: boolean,
  defaultChecked?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void | boolean> | void
}

export const Radio: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(function Radio({
  name,
  value,
  disabled= false,
  defaultChecked= false,
  children,
  onChange =() => {},
                                                                      }, ref){

  return (
    <div className={styles.wrapper}>
      <input ref={ref} className={styles.radio} id={value} name={name} type="radio" value={value} disabled={disabled} defaultChecked={defaultChecked} onChange={onChange}/>
      <label htmlFor={value}>{children}</label>
    </div>)
})
