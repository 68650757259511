import classes from './SubmittedFormMessage.module.scss'
import successImage from '../../../images/success_message/success.png'


export function SubmittedFormMessage() {
   return (
      <div className={classes.backForm}>
         <div className={classes.img}>
            <img src={successImage} alt='success_img' />
         </div>
         <h2>
            Your message has been
            successfully sent
         </h2>
         <p>We will reply to you as soon
            as possible.
         </p>
      </div>
   )
}