import React from 'react'
import { Button } from '../../../../components/Button'
import classes from './Header.module.scss'

import header_image from '../../../../images/header_images/David_Lendos 1.png'
import { useBorrowerAppNavigation } from '../../../../hooks/useBorrowerAppNavigation'

type Props = {
   id: string,
}

export const SectionTop = React.forwardRef<HTMLHeadingElement, Props>(function SectionTop({
   id,
}, ref) {
   const borrowerAppNavigation = useBorrowerAppNavigation()


   function handleStartNow() {
      borrowerAppNavigation.push('/auth/registration')
   }


   return (
      <header className={classes.header} id={id} ref={ref}>
         <div className={classes.container}>
            <div className={classes.header__content}>
               <div className={classes.header__text}>
                  <div className={classes.header__title}>
                     <h2>
                     Take your Amazon business to new heights
                     </h2>
                  </div>
                  <div className={`${classes.header__image} ${classes.header__image_mobile}`}>
                     <img src={header_image} alt='logo' />
                  </div>
                  <div className={classes.header__text__subtitle}>
                     <p>
                     Are you facing a cash crunch, or do you need funds to advance your business? Get up to $250k in capital to take your stock, sales, and profits to the next level.
                     </p>
                  </div>
                  <div className={classes.header__button}>
                     <Button color='accent' className={classes.startNow} onClick={handleStartNow}>Start now</Button>
                  </div>
                  <div className={classes.header__text__paragraph}>
                     <p>
                     Securing funding for your e-commerce business can be weary. But Lendos takes the burden off your shoulders by providing sufficient loans in record time.
                     </p>
                  </div>
               </div>
               <div className={classes.header__image}>
                  <img src={header_image} alt='logo' />
               </div>
            </div>
         </div>
      </header>
   )
})