
export const documents = [
    {
        name: 'Terms of Service',
        link: 'terms-of-service.pdf',
        description: 'Agreement with a customer that signs up to a Lendos account'
    },
    {
        name: 'Privacy Policy',
        link: 'privacy-policy.pdf',
        description: 'Our privacy statement that describe what information we collect, how we use it and what choices you have regarding your personal information'
    },
    {
        name: 'Cookies Policy',
        link: 'cookies-policy.pdf',
        description: 'Our privacy statement that describe what information we collect, how we use it and what choices you have regarding your personal information'
    }
]