import React from 'react'
import classes from './SectionBenefits.module.scss'

import firstImage from '../../../../images/benefits_images/first_image.svg'
import secondImage from '../../../../images/benefits_images/second_image.svg'
import thirdImage from '../../../../images/benefits_images/third_image.svg'
import fourthImage from '../../../../images/benefits_images/fourth_image.svg'

type Props = {
   id: string,
}

export const SectionBenefits = React.forwardRef<HTMLDivElement, Props>(function SectionBenefits({
   id
}, ref) {
   return (
      <div className={classes.container} id={id} ref={ref}>
            <div className={classes.benefits}>
            <h2 className={classes.benefits__title}>How we help businesses grow.</h2>
            <h3 className={classes.benefits__subtitle}>
               We help you sustain your Amazon business with a stream of capital at your disposal.
            </h3>
            <div className={classes.benefits__images}>

               <div className={`${classes.benefits__images_item} ${classes.first_child}`} >
                  <img src={firstImage} className={classes.item__img} alt='first' />
                  <div className={classes.item__title}>Quick online lending</div>
                  <div className={classes.item__subtitle}>
                     No paperwork, no fuss. Connect your marketplace account and apply in minutes.
                  </div>
               </div>
               <div className={classes.benefits__images_item} >
                  <img src={secondImage} className={classes.item__img} alt='second' />
                  <div className={classes.item__title}>No hidden charges</div>
                  <div className={classes.item__subtitle}>Applying is free. You just have to qualify.</div>
               </div>
               <div className={classes.benefits__images_item} >
                  <img src={thirdImage} className={classes.item__img} alt='third' />
                  <div className={classes.item__title}>Fast and simple</div>
                  <div className={classes.item__subtitle}>You'll receive the funds via ACH or wire transfer on the day the loan is approved.</div>
               </div>
               <div className={`${classes.benefits__images_item} ${classes.last_child}`} >
                  <img src={fourthImage} className={classes.item__img} alt='fourth' />
                  <div className={classes.item__title}>High approval rates</div>
                  <div className={classes.item__subtitle}>Unlike other loan services, we collect your business info directly from Amazon and perform the scoring ourselves.</div>
               </div>
            </div>
         </div>
      </div>
   )
}

)