import { useCallback } from 'react';

export function getBorrowerAppLink(path: string) {
  return `https://app.${window.location.host}${path}`
}

export const useBorrowerAppNavigation = () => {
  const push = useCallback((path: string) => {
    window.location.href = getBorrowerAppLink(path)
  }, [])

  return {
    push
  }
}