import { RegisterOptions } from "react-hook-form";

export const NameValidation: RegisterOptions = {
  required: "Required field",
  minLength: {
    value: 3,
    message: 'At least 3 characters'
  }
}

const EmailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const EmailValidation: RegisterOptions = {
  required: 'Required field',
  pattern: {
    value: EmailRegExp,
    message: 'Wrong email format'
  }
}

const PhoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/

export const PhoneValidation: RegisterOptions = {
  pattern: {
    value: PhoneRegExp,
    message: 'Wrong phone number format'
  }
}

export const MessageValidation: RegisterOptions = {
  required: "Required field",
  minLength: {
    value: 3,
    message: "At least 10 characters"
  }
}