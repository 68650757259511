import { Footer } from '../parts/Footer'
import { Navigation } from '../parts/Navigation'
import styles from './LegalPage.module.scss'
import {Typography} from '../../components/Typography';
import {documents} from './documents';

export function LegalPage() {

   return (
      <div className="App">
         <Navigation />
         <div className={styles.container}>
             <Typography size="3xl" weight="bold">Lendos Legal</Typography>
             {documents.map((document) => (
                 <div>
                     <Typography size="l" weight="bold" className={styles.link}>
                         <a href={document.link} key={document.link} target="_blank" rel="noreferrer">{document.name}</a>
                     </Typography>
                     <Typography size="m">{document.description}</Typography>
                 </div>
             ))}
         </div>
         <Footer />
      </div>
   )
}