// @ts-nocheck
import React from 'react'
import { NavLink } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'
import classNames from 'classnames/bind'

import { Button } from '../../../components/Button'


import logo from '../../../images/logo.svg'
import classes from './Navigation.module.scss'
import { useState } from 'react'
import { useBorrowerAppNavigation } from '../../../hooks/useBorrowerAppNavigation'

const cx = classNames.bind(classes)

type Props = {
   activeLinks?: {
      top: boolean,
      sectionBenefits: boolean,
      sectionStages: boolean,
      sectionContactForm: boolean
   }
}

export const Navigation: React.FC<Props> = (function Navigation({
   activeLinks = {
      top: false,
      sectionBenefits: false,
      sectionStages: false,
      sectionContactForm: false
   }
}) {

   const body = document.body

   const borrowerAppNavigation = useBorrowerAppNavigation();
   const [isNavOpen, toggleNav] = useState(false)

   let classesToggleButton = cx({
      nav__button: true,
      active: isNavOpen
   })

   let classesNavBoddyWrapper = cx({
      nav__list_wrapper: true,
      active: isNavOpen
   })

   function handleClick() {
      borrowerAppNavigation.push('/auth/login')
   }

   function navToggle() {
      toggleNav(isNavOpen => !isNavOpen)
      body.classList.toggle('lock')
   }

   function toggleBoduLock() {
      body.classList.remove('lock')
      toggleNav(isNavOpen => !isNavOpen)
   }

   function scroll(element: HTMLElement) {
      const offset = -90;
      const top = element.getBoundingClientRect().top + window.scrollY + offset;
      window.scrollTo({top: (top >= 0) ? top : 0, behavior: 'smooth'});
   }

   return (
      <nav className={classes.nav}>
         <div className={classes.nav__wrapper}>
            <div className={classes.logo}>
            <NavHashLink
               scroll={scroll}
               to={`/#top`}
            >
               <img src={logo} alt='logo' />
            </NavHashLink>
            </div>
            <div className={classesToggleButton}
               onClick={navToggle}
            >
               <span />
            </div>
            <div className={classesNavBoddyWrapper}>
               <ul className={classes.nav__list}>
                  <li className={activeLinks.sectionBenefits ? classes.selected : ''}>
                     <NavHashLink
                        scroll={scroll}
                        to={`/#benefits`}
                        onClick={toggleBoduLock}
                     >Benefits</NavHashLink>
                  </li>
                  <li className={activeLinks.sectionStages ? classes.selected : ''}>
                     <NavHashLink
                        scroll={scroll}
                        to={`/#stages`}
                        onClick={toggleBoduLock}
                     >Stages</NavHashLink>
                  </li>
                  <li className={activeLinks.sectionContactForm ? classes.selected : ''}>
                     <NavHashLink
                        scroll={scroll}
                        to={`/#contactForm`}
                        onClick={toggleBoduLock}
                     >Contacts</NavHashLink>
                  </li>
                  <li>
                     <NavLink to='/become-lender'
                        onClick={toggleBoduLock}
                     >For Investors</NavLink>
                  </li>
               </ul>
               <Button className={classes.nav__joinButton} color='accent' onClick={handleClick}>Log In</Button>
            </div>
         </div>
      </nav>
   )
}
)