import React from 'react'
import { ContactForm } from './parts/ContactForm'
import classes from './SectionContactForm.module.scss'

type Props = {
  id: string,
  referense: any
}

export const SectionContactForm: React.FC<Props> = React.forwardRef<HTMLBodyElement, Props>(function SectionContactForm({
  id, referense
}, ref) {

  return (
    <section className={classes.container}>
      <div className={classes.content}>
        <div className={classes.form_block__text}>
          <div className={classes.form_block__text__wrapper}>
            <h2 id={id} ref={referense}>Get in Touch.</h2>
            <p>
              We’re always ready to assist you. Please contact us if you have any questions about Lendos, loan terms, or how to apply.
            </p>
          </div>


        </div>
        <ContactForm />
      </div>
    </section>
  )
})