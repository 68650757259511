import { useCallback, useMemo, useRef, useState } from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import classNames from 'classnames/bind'
import ReCAPTCHA from 'react-google-recaptcha';

import { captchaToken } from '../../../../../../configs/captcha';

import {
   NameValidation,
   EmailValidation,
   MessageValidation
} from "../../../../../../hooks/useForm";

import { api } from '../../../../../../api'
import { Feedback } from "../../../../../../types/types"

import { Button } from '../../../../../../components/Button'
import { Input } from '../../../../../../components/Input'
import { Radio } from "../../../../../../components/Radio";
import { TextArea } from "../../../../../../components/TextArea";

import { SubmittedFormMessage } from '../../../../../parts/SubmittedFormMessage'

import classes from './ContactForm.module.scss'
import { SendFeedbackPayload } from '../../../../../../api/types';



const cx = classNames.bind(classes)

export const ContactForm = () => {
   const recaptchaRef = useRef<ReCAPTCHA>(null);
   const [captchaPassed, setCaptchaPassed] = useState(false);
   const { register, handleSubmit, formState: { isDirty, errors, isSubmitSuccessful, isSubmitting } } = useForm<Feedback>();

   const onSubmit: SubmitHandler<SendFeedbackPayload['request']> = useCallback(async (request) => {
      const captcha = recaptchaRef.current?.getValue();
      console.log(request)
      if (!captcha) return Promise.reject()

      await api.sendFeedback({
         request,
         captcha
      });
   }, []);

   const messageTypeFieldRegistration = useMemo(() => register('theme'), [register])

   let className = cx({
      card: true,
      submitted: isSubmitSuccessful
   })

   return (
      <div className={className}>
         <div className={classes.front}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
               <fieldset disabled={isSubmitting}>
                  <div className={classes.form__content}>
                     <Input {...register('name', { ...NameValidation })} placeholder="Your Name*" type="text" error={errors.name?.message} />
                     <Input {...register('email', { ...EmailValidation })} placeholder="Your Email*" type="email" error={errors.email?.message} />
                     <p>Theme:</p>
                     <ul className={classes.form__option}>
                        <li><Radio {...messageTypeFieldRegistration} name="theme" value="Registration">Registration</Radio></li>
                        <li><Radio {...messageTypeFieldRegistration} name="theme" value="Problems">Problems</Radio></li>
                        <li><Radio {...messageTypeFieldRegistration} name="theme" value="Suggestions">Suggestions</Radio></li>
                        <li><Radio {...messageTypeFieldRegistration} name="theme" value="Question" defaultChecked>Other</Radio></li>
                     </ul>
                     <TextArea className={classes.form__text} {...register('message', { ...MessageValidation })} placeholder="Text here" error={errors.message?.message} />

                     <div className={classes['recaptcha-box']}>
                        <ReCAPTCHA
                           onChange={() => setCaptchaPassed(true)}
                           onExpired={() => setCaptchaPassed(false)}
                           ref={recaptchaRef}
                           sitekey={captchaToken}
                        />
                     </div>
                     <Button
                        className={classes.form__button}
                        disabled={!captchaPassed || (isSubmitting || !isDirty)}
                        loading={isSubmitting}
                     >
                        Send
                     </Button>
                  </div>
               </fieldset>
            </form>
         </div>
         <div className={classes.back}>
            <SubmittedFormMessage />
         </div>
      </div>
   )
}