import { SectionTop } from './parts/Header'
import { SectionBenefits } from './parts/SectionBenefits'
import { SectionStages } from './parts/SectionStages'
import { SectionContactForm } from './parts/SectionContactForm'
import { Footer } from '../parts/Footer'
import { useInView } from "react-intersection-observer"
import { Navigation } from '../parts/Navigation'

export function MainPage() {
   const { ref: refSectionTop, inView: inViewSectionTop } = useInView({ threshold: 0.5 })
   const { ref: refSectionBenefits, inView: inViewSectionBenefits } = useInView({ threshold: .65 })
   const { ref: refSectionStages, inView: inViewSectionStages } = useInView({ threshold: 1 })
   const { ref: refSectionContactForm, inView: inViewSectionContactForm } = useInView({ threshold: 1 })

   const activeLinks = {
      top: inViewSectionTop,
      sectionBenefits: inViewSectionBenefits,
      sectionStages: inViewSectionStages,
      sectionContactForm: inViewSectionContactForm
   }

   return (
      <div className="App">
         <Navigation activeLinks={activeLinks} />
         <SectionTop ref={refSectionTop} id='top' />
         <SectionBenefits ref={refSectionBenefits} id='benefits' />
         <SectionStages ref={refSectionStages} id='stages' />
         <SectionContactForm referense={refSectionContactForm} id='contactForm' />
         <Footer />
      </div>
   )
}