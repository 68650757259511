import { NavLink } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'

import classes from './Footer.module.scss'

import logo from '../../../images/logo.svg'
import message from '../../../images/footer_images/message.png'

function scroll(element: HTMLElement) {
   const offset = -90;
   const top = element.getBoundingClientRect().top + window.scrollY + offset;
   window.scrollTo({top: (top >= 0) ? top : 0, behavior: 'smooth'});
}

export function Footer() {
   return (
      <footer className={classes.footer} id='footer'>
         <div className={classes.container}>
            <div className={classes.footer__nav}>
               <div className={classes.footer__nav_info}>
                  <div className={classes.footer__logo}>
                     <img src={logo} alt='logo' />
                  </div>
                  <a className={classes.link} href="mailto:hello@lendos.capital">
                     <div className={classes.footer__email}>
                        <img src={message} alt='email_message' />
                        <p>hello@lendos.capital</p>
                     </div>
                  </a>
               </div>
               <div className={classes.footer_links_list}>
                  <ul>
                     <li> <NavHashLink scroll={scroll} to='/#top'>Home</NavHashLink></li>
                     <li> <NavHashLink scroll={scroll} to='/#benefits'>Benefits</NavHashLink></li>
                     <li> <NavHashLink scroll={scroll} to='/#stages'> Stages</NavHashLink> </li>
                     <li> <NavHashLink scroll={scroll} to='/#contactForm'>Contacts</NavHashLink> </li>
                     <li><NavLink to='/become-lender'> For Investors</NavLink></li>
                  </ul>
               </div>
            </div>
            <div className={classes['footer-bottom']}>
            <div className={classes.copyright}>
                  © Lendos Inc. 2022 | Copyright
            </div>
               <div className={classes.links}>
                  <NavLink to='/legal'>Terms of Service</NavLink>
                  {' | '}
                  <NavLink to='/legal'>Privacy Policy</NavLink>
                  {' | '}
                  <NavLink to='/legal'>Cookies Policy</NavLink>
               </div>
            </div>
         </div>
      </footer>
   )
}