import { Route, Switch, Redirect } from 'react-router-dom'
import { MainPage } from './pages/MainPage'
import { LegalPage } from './pages/LegalPage';
import { BecomeInvestor } from './pages/BecomeInvestor'
import { NotFound } from './pages/NotFound';

import './styles/global.scss'

function App() {

  return (
    <Switch>
      <Route path="/not-found" component={NotFound} />
      <Route path="/" component={MainPage} exact />
      <Route path="/legal" component={LegalPage} exact/>
      <Route path="/become-lender" component={BecomeInvestor} exact />
      <Redirect to={'/not-found'} />
    </Switch>
  )
}

export default App
