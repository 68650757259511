import classNames from 'classnames/bind';

import styles from './Typography.module.scss';

const cx = classNames.bind(styles);

type size = '3xl' | '2xl' | 'xl' | 'l' | 'm' | 's' | 'xs';
type Weight = 'extra-light' | 'light' | 'normal' | 'medium' | 'semi-bold' | 'bold' | 'extra-bold';

type TypographyProps = {
  size?: size;
  color?: Color;
  className?: string;
  weight?: Weight;
};

const Typography: React.FC<TypographyProps> = ({
  size = 's',
  weight = 'normal',
  color,
  className,
  children,
}) => {
  return (
    <p
      className={cx('typography', `typography--${size}`, `typography--${weight}`, className)}
      style={{
        color: color ? `var(--color-${color})` : 'inherit',
      }}
    >
      {children}
    </p>
  );
};

export { Typography };
