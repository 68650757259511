import React from 'react'
import classNames from 'classnames/bind'
import styles from './Button.module.scss'

const cx = classNames.bind(styles)

type Props = {
  color?: 'primary' | 'accent'
  disabled?: boolean,
  loading?: boolean,
  type?: "button" | "submit" | "reset"
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void
  className?: string,
}

export const Button: React.FC<Props> = React.forwardRef<HTMLButtonElement, Props>(function Button({
  color = 'primary',
  disabled = false,
  loading = false,
  type,
  onClick = () => { },
  className = '',
  children
}, ref) {
  let classes = cx(
    className,
    {
      btn: true,
      [`btn--${color}`]: true,
      'btn--loading': loading,
    })

  return (
    <button ref={ref} className={classes} disabled={disabled} type={type} onClick={onClick}>
      {loading ? 'Processing...' : children}
    </button>
  )
})