import React from 'react'

import { Button } from '../../../../components/Button'
import { useBorrowerAppNavigation } from '../../../../hooks/useBorrowerAppNavigation'

import classes from './SectionStages.module.scss'

type Props = {
   id: string,
}

export const SectionStages = React.forwardRef<HTMLDivElement, Props>(function SectionStages({
   id
}, ref) {
   const borrowerAppNavigation = useBorrowerAppNavigation()


   function handleStartNow() {
      borrowerAppNavigation.push('/auth/registration')
   }

   return (
      <div className={classes.container} id={id} ref={ref}>
         <div className={classes.stage}>
            <h2 className={classes.stage__title}>In just three steps.</h2>
            <div className={classes.stage__subtitle}>
               <p>
               Data-driven, fast business loans - we're transforming e-commerce funding by simplifying the loan process.
               </p>
            </div>
            <div className={classes.stage__items}>
               <div className={classes.stage_item}>
                  <div className={classes.stage_item__number}>
                     1
                  </div>
                  <div className={classes.stage_item__title}>
                     <h3>Submit an application</h3>
                  </div>
                  <div className={classes.stage_item__subtitle}>
                     <p>We provide the best loan offers based on your business's financial health and sales history.</p>
                  </div>
               </div>
               <div className={classes.stage_item}>
                  <div className={classes.stage_item__number}>
                     2
                  </div>
                  <div className={classes.stage_item__title}>
                     <h3>Get an approval</h3>
                  </div>
                  <div className={classes.stage_item__subtitle}>
                     <p>Our innovative approach to scoring guarantees a high loan approval rate.</p>
                  </div>
               </div>
               <div className={classes.stage_item}>
                  <div className={classes.stage_item__number}>
                     3
                  </div>
                  <div className={classes.stage_item__title}>
                     <h3>Get your funds</h3>
                  </div>
                  <div className={classes.stage_item__subtitle}>
                     <p>Receive your funds within 24hrs of loan approval via ACH.</p>
                  </div>
               </div>
            </div>
               <Button className={classes.stage__button} onClick={handleStartNow}>Start now</Button>
         </div>
      </div>
   )
}

)
