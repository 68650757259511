import { ReactNode } from "react"
import logo from '../../images/logo.png'
import styles from './FeedbackLayout.module.scss';
import { NavHashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import { Navigation } from "../../pages/parts/Navigation";

type FeedbackLayoutProps = {
  title: string,
  text: string | ReactNode,
  form: ReactNode
}

export const FeedbackLayout = ({ title, text, form }: FeedbackLayoutProps) => {
  return (
    <>
      <div className={styles.mobileNavigation}>
        <Navigation />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.infoWrapper}>
          <div className={styles.info}>
            <nav className={styles.logo}>
              <NavHashLink smooth to={`/#top`}>
                <img src={logo} alt='logo' />
              </NavHashLink>
            </nav>
            <h2 className={styles.title}>
              { title }
            </h2>
            <div className={styles.text}>
              { (typeof text === 'string') 
                ? (
                  <p>
                    { text }
                  </p>)
                : text }
            </div>
            <a href="mailto:hello@lendos.capital" className={styles.email}>
              hello@lendos.capital
            </a>
          </div>
        </div>
        <div className={styles.formWrapper}>
          <div className={styles.homeWrapper}>
          <nav className={styles.home}>
            <NavLink to='/'>Home</NavLink>
          </nav>
          </div>
          <div className={styles.form}>
            { form }
          </div>
        </div>
      </div>
    </>
  )
}