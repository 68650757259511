import { useCallback, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../api";
import { Button } from "../../components/Button";
import { FeedbackLayout } from "../../components/FeedbackLayout";
import { Input } from "../../components/Input";
import { EmailValidation, NameValidation, PhoneValidation } from "../../hooks/useForm";
import { Lender } from "../../types/types";
import { SubmittedFormMessage } from "../parts/SubmittedFormMessage";
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './styles.module.scss';
import { BecomeLenderPayload } from '../../api/types';
import { captchaToken } from '../../configs/captcha';

export const BecomeInvestor = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [captchaPassed, setCaptchaPassed] = useState(false);

  const { register, handleSubmit, formState: { isDirty, errors, isSubmitSuccessful, isSubmitting } } = useForm<Lender>();

  const onSubmit: SubmitHandler<BecomeLenderPayload['lender']> = useCallback(async (lender) => {
    const captcha = recaptchaRef.current?.getValue();

    if (!captcha) return Promise.reject()

    await api.becomeLender({
      lender,
      captcha
    });
  }, []);

  const renderForm = () => (
    !isSubmitSuccessful
      ? <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isSubmitting}>
          <Input {...register('name', { ...NameValidation })} placeholder="Name*" type="text" error={errors.name?.message} />
          <Input {...register('email', { ...EmailValidation })} placeholder="Email*" type="email" error={errors.email?.message} />
          <Input {...register('companyName', {})} placeholder="Company Name" type="text" />
          <Input {...register('phone', { ...PhoneValidation })} placeholder="Phone" type="text" error={errors.phone?.message} />
          <div className={styles['recaptcha-box']} >
            <ReCAPTCHA
              onChange={() => setCaptchaPassed(true)}
              onExpired={() => setCaptchaPassed(false)}
              ref={recaptchaRef}
              sitekey={captchaToken}
            />
          </div>
        </fieldset>
        <Button disabled={!captchaPassed || (isSubmitting || !isDirty)} loading={isSubmitting}>Send Request</Button>
      </form>
      : <SubmittedFormMessage />
  )

  const renderText = () => (
    <>
      <p>
        We find creditworthy borrowers, and you invest in their businesses via loans. Then we deposit scheduled installments directly into your account. You also retain control: reinvest, take profits, or withdraw your capital whenever you want.
        <br /><br />
        Let’s start a conversation! Tell us a bit about yourself.
      </p>
    </>
  )

  return <FeedbackLayout
    title="Dear Lender!"
    text={renderText()}
    form={renderForm()}
  />
}