import React from 'react'

import styles from './TextArea.module.scss'

type Props = {
  name: string
  placeholder?: string,
  error?: string
  disabled?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => Promise<void | boolean> | void
  className?: string,
}

export const TextArea: React.FC<Props> = React.forwardRef<HTMLTextAreaElement, Props>(function TextArea({
  name,
  placeholder,
  error = '',
  disabled = false,
  onChange = () => { },
  className = '',
}, ref) {

  return (<div className={styles.wrapper}>
    <textarea ref={ref} className={`${styles.textarea} ${className}`} name={name} placeholder={placeholder} disabled={disabled} onChange={onChange} />
    {error && <div className={styles.hint}>{error}</div>}
  </div>)
})