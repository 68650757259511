import React from 'react'

import styles from './Input.module.scss'

type Props = {
  name: string
  type?: "text" | "email"
  placeholder?: string,
  error?: string
  disabled?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void | boolean> | void
}

export const Input: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(function Input({
  name,
  type = 'text',
  placeholder,
  error = '',
  disabled = false,
  onChange = () => { },
}, ref) {

  return (<div className={styles.wrapper}>
    <input ref={ref} className={styles.input} name={name} type={type} placeholder={placeholder} disabled={disabled} onChange={onChange} />
    <div className={styles.hint}>{error}</div>
  </div>)
})